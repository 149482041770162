


/* eslint-disable react/react-in-jsx-scope */
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getUserById, userLogin } from 'src/feature/user/userActions'
import Error from 'src/components/Error'
import React from 'react'
import { Link } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { CircularProgress, Typography } from '@mui/material'
import { toast } from 'react-toastify'

const Login = () => {

  const { loading, userInfo, error, role, userId } = useSelector((state) => state.user)
  console.log("Roleeeeeee:  " + role);
  console.log("User Id:  " + userId);

  const dispatch = useDispatch()

  const { register, handleSubmit } = useForm()

  const navigate = useNavigate()

  // redirect authenticated user to profile screen
  useEffect(() => {
    if (userInfo && role === 'ADMIN') {
      navigate('/dashboard')
    } else if (userInfo && role === 'PHARMACY') {
      navigate('/pharmacy/medicine')
    }
    else if (userInfo && role === 'LABORATORY') {
      navigate('/lab/testOrder')
    } else {
      navigate('/login')
    }
  }, [role, userInfo])

  const submitForm = (cred) => {
    console.log(cred);
    dispatch(userLogin(cred))
    // dispatch(getUserById(userId))
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit(submitForm)}>
                    {/* {error && <Error>{error}</Error>} */}
                    {error && <Error>Username or email is incorrect</Error>}
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput       {...register('username')} placeholder="Username" autoComplete="username" />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        {...register('password')}
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton color="primary" className="px-4" type='submit' disabled={loading}>
                          {loading ? <CircularProgress sx={{ ml: 2, mr: 2 }} size={30} /> : <Typography> Login</Typography>}

                        </CButton>
                      </CCol>
                      <CCol xs={6} className="text-right">
                        <CButton color="link" className="px-0">
                          Forgot password?
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-primary py-5" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Are you A guest?</h2>
                    <p>
                      Welcome to YALA, where high-quality healthcare is just a click away. Whether you are seeking medical advice, managing chronic conditions, or needing mental health support, our team of licensed professionals is ready to assist you from the comfort of your home.
                    </p>
                    <Link to="#">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>
                        Guest Page
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login